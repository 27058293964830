import Image1 from './../assets/images/calculator.png'
import Image2 from './../assets/images/abacus.png'
import Image3 from './../assets/images/games.png'

const data = [
  /*{
    title: 'Truth and Provability: Gödel\'s Incompleteness Theorems',
    website: 'https://ms.mcmaster.ca/~bradd/courses/math3tp3/',
    description: ``,
    location: '../../markdown/godel/',
    image: Image1
  },
  {
    title: 'Number Theory',
    website: 'https://ms.mcmaster.ca/~vantuyl/courses/2020_winter_math3H03.html',
    description: ``,
    location: '../../markdown/numbertheory/',
    image: Image2
  }*/
  {
    title: 'Game Theory',
    website: 'https://www.coursera.org/learn/game-theory-1/',
    description: `Jackson, Shoham, Leyton-Brown`,
    location: '../../gametheory/',
    image: Image3
  },
]

export default data
